<template>
  <div class="content">
    <PageHeader :title="$t('campaign-detail-component.title')" />
    <div class="page-content container-fluid">
      <div class="card">
        <div class="card-header">
          <div class="form-row">
            <div class="col-lg-2 col-md-2 m-top-10">
              <input class="form-control" :placeholder="$t('generic-str.lbl-sender')" v-model="form.from" />
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <input class="form-control" :placeholder="$t('generic-str.lbl-recipient')" v-model="form.to" />
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <select class="form-control" v-model="form.status">
                <option selected value>Status</option>
                <option value="accepted">{{$tc('generic-str.status.lbl-pending', 1)}}</option>
                <option value="rejected">{{$t('generic-str.status.lbl-rejected')}}</option>
                <option value="queued">{{$t('generic-str.status.lbl-queue')}}</option>
                <option value="sending">{{$t('generic-str.status.lbl-sending')}}</option>
                <option value="sent">{{$t('generic-str.status.lbl-sent')}}</option>
                <option value="failed">{{$t('generic-str.status.lbl-failure')}}</option>
                <option value="delivered">{{$t('generic-str.status.lbl-delivered')}}</option>
                <option value="undelivered">{{$t('generic-str.status.lbl-unavailable')}}</option>
                <option value="received">{{$tc('generic-str.status.lbl-response', 2)}}</option>
              </select>
            </div>
            <div class="col-lg-2 col-md-2 m-top-10">
              <div class="form-group">
                <div class="input-group">
                  <div class>
                    <button
                      class="btn btn-danger btn-sm"
                      @click="fetch(form.page)"
                    >{{$t('generic-str.filter')}}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body block-el p-0">
          <div class="responsive-table">
            <table v-if="fetched" class="table">
              <thead class="bg-light">
                <tr>
                  <th scope="col">{{$t('generic-str.date')}}</th>
                  <th scope="col">{{$t('generic-str.type')}}</th>
                  <th scope="col">{{$t('generic-str.lbl-sender')}}</th>
                  <th scope="col">{{$t('generic-str.lbl-recipient')}}</th>
                  <th scope="col">{{$tc('generic-str.message', 1)}}</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody>
                <router-link
                  v-for="message in messages"
                  :key="message.id"
                  :to="`/rcs/history/${message.id}`"
                  tag="tr"
                >
                  <td data-label="Data">
                    <div
                      class="max-200"
                      v-tooltip.top="`${message.created_at | formatDate24}`"
                    >{{ message.created_at | formatDate24}}</div>
                  </td>
                  <td data-label="Tipo">
                    <template v-if="message.direction == 'outbound'">{{$t('generic-str.send-2')}}</template>
                    <template v-else-if="message.direction == 'inbound'">{{$tc('generic-str.status.lbl-response', 1)}}</template>
                  </td>
                  <td data-label="Remetente">{{ message.from }}</td>
                  <td data-label="Destinatário">{{ message.to }}</td>
                  <td data-label="Mensagem">
                    <router-link :to="`/rcs/history/${message.id}`">
                      {{$t('generic-str.view-msg')}}
                    </router-link>
                  </td>
                  <td data-label="Status">
                    <span
                      v-if="message.status == 'read'"
                      class="badge badge-info"
                      >{{$t('generic-str.status.lbl-readed')}}</span
                    >
                    <span
                      v-if="message.status == 'accepted'"
                      class="badge badge-light"
                      >{{$tc('generic-str.status.lbl-pending', 1)}}</span
                    >
                    <span
                      v-if="message.status == 'scheduled'"
                      class="badge badge-light"
                      >Agendado</span
                    >
                    <span
                      v-else-if="message.status == 'queued'"
                      class="badge badge-secondary"
                      >{{$t('generic-str.status.lbl-queue')}}</span
                    >
                    <span
                      v-else-if="message.status == 'sending'"
                      class="badge badge-accent"
                      >{{$t('generic-str.status.lbl-sending')}}</span
                    >
                    <span
                      v-else-if="message.status == 'sent'"
                      class="badge badge-info"
                      >{{$t('generic-str.status.lbl-sent')}}</span
                    >
                    <span
                      v-else-if="message.status == 'failed'"
                      class="badge badge-danger"
                      v-tooltip.top="
                        `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                      "
                      >{{$t('generic-str.status.lbl-failure')}}</span
                    >
                    <span
                      v-else-if="message.status == 'delivered'"
                      class="badge badge-success"
                      >{{$t('generic-str.status.lbl-delivered')}}</span
                    >
                    <span
                      v-else-if="message.status == 'undelivered'"
                      class="badge badge-dark"
                      v-tooltip.top="
                        `${$t('sms.infos.filters.status.lbl-error')} ${message.error_code}: ${message.error_message}`
                      "
                      >{{$t('generic-str.status.lbl-unavailable')}}</span
                    >
                    <span
                      v-else-if="message.status == 'received'"
                      class="badge badge-success"
                      >{{$tc('generic-str.status.lbl-response', 1)}}</span
                    >
                  </td>
                </router-link>
              </tbody>
            </table>
            <div v-else class="static qt-block-ui" style="padding:120px;" />
            <!-- <div v-else class="relative qt-block-ui" style="padding:120px;" /> -->
            <br />
            <pagination :lastPage="pages" @change="fetch" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/PageHeader.vue';
import Pagination from '@/components/Pagination.vue';
import RCSService from '@/services/rcs.service';
// import moment from 'moment';

export default {
  name: 'SmsDashboard',
  components: {
    PageHeader,
    Pagination,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      fetched: false,
      form: {
        status: '',
        from: '',
        to: '',
        campaign_id: this.$route.params.id,
      },
      pages: 1,
      exporting: false,
    };
  },
  created() {
    this.fetch(1);
  },
  methods: {
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      RCSService.filterMessages(this.form).then(
        (response) => {
          this.fetched = true;
          this.messages = response.data;
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
          console.log(error);
        },
      );
    },
  },
};
</script>

<style scoped lang="scss">
.btn-search-id {
  height: 35px;
}
.btn-export {
  margin-left: 1px;
  padding: 8px 20px !important;
}
.m-top-10 {
  margin-top: 10px;
}
</style>
